import React, { useEffect, useState } from "react";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import "./Leaderboard.css";

const Leaderboard = () => {
  const [data, setData] = useState(null);
  const [countdown, setCountdown] = useState("");

  const getCurrentDateRange = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    let startDate, endDate;
    const currentDay = today.getDate();

    if (currentDay >= 1 && currentDay <= 7) {
      startDate = `${currentYear}-${currentMonth}-01`;
      endDate = `${currentYear}-${currentMonth}-07`;
    } else if (currentDay >= 8 && currentDay <= 14) {
      startDate = `${currentYear}-${currentMonth}-08`;
      endDate = `${currentYear}-${currentMonth}-14`;
    } else if (currentDay >= 15 && currentDay <= 21) {
      startDate = `${currentYear}-${currentMonth}-15`;
      endDate = `${currentYear}-${currentMonth}-21`;
    } else {
      const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
      startDate = `${currentYear}-${currentMonth}-22`;
      endDate = `${currentYear}-${currentMonth}-${lastDayOfMonth}`;
    }

    return { startDate, endDate };
  };

  const getNextWeekStartDate = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    const currentDay = today.getDate();
    let nextWeekStart;

    if (currentDay >= 1 && currentDay <= 7) {
      nextWeekStart = new Date(currentYear, currentMonth - 1, 8);
    } else if (currentDay >= 8 && currentDay <= 14) {
      nextWeekStart = new Date(currentYear, currentMonth - 1, 15);
    } else if (currentDay >= 15 && currentDay <= 21) {
      nextWeekStart = new Date(currentYear, currentMonth - 1, 22);
    } else {
      // Move to the 1st of the next month
      const nextMonth = currentMonth;
      nextWeekStart = new Date(currentYear, nextMonth, 1);
    }

    // Set the time to midnight (start of the next week)
    nextWeekStart.setHours(0, 0, 0, 0);

    return nextWeekStart;
  };

  const calculateCountdown = () => {
    const nextWeekStart = getNextWeekStartDate();
    const now = new Date();
    const timeDiff = nextWeekStart - now;

    // Calculate the remaining hours, minutes, and seconds
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDiff / 1000) % 60);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const getLeaderboard = async () => {
    const { startDate, endDate } = getCurrentDateRange();

    try {
      const res = await axios.get(
        `/api/user/leaderboard?startDate=${startDate}&endDate=${endDate}`
      );
      if (res.data.success) {
        const emails = res.data.data;
        const updatedEmails = emails.map((item) => {
          if (item?.fname?.includes("@gmail.com")) {
            return {
              ...item,
              fname: item.fname.replace("@gmail.com", ""),
            };
          }
          return item;
        });
        setData(updatedEmails);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getLeaderboard();

    const countdownInterval = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    return () => clearInterval(countdownInterval); // Clear the interval on component unmount
  }, []);

  return (
    <Layout>
      <div className="leaderboard-container">
        <h4 className="heading">The Leaderboard will reset in: {countdown}</h4>
        {data && data?.length === 0 ? (
          <div className="norecord">
            <h5 className="m-0">NO RECORD</h5>
          </div>
        ) : (
          data?.map((item, index) => {
            return (
              <div className="items" key={index}>
                <span>
                  <p>{index + 1 + ")"}</p>
                  {item?.fname}
                </span>
                <span>₹{item?.totalSpent}</span>
              </div>
            );
          })
        )}
      </div>
    </Layout>
  );
};

export default Leaderboard;
