import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "../Footer/Footer.css";

const Footer = () => {
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        <div className="wa-icon">
          <Link to="https://wa.me/919366479173">
            <WhatsAppIcon className="icon waicon" />
          </Link>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
            <div className="footer-logo">
              Dream<span>Store</span>
            </div>
            <span>+91 9366479173</span>
            <br />
            <span>devilsun402@gmail.com</span>
            <br />
            <span>India</span>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-4 col-lg-4 mb-4">
            <h6>Quick Links</h6>
            <ul>
              <li>
                <Link to="/leaderboard">Leaderboard</Link>
              </li>
              {!user ? (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                </>
              ) : (
                <li>
                  <Link to="/user-dashboard">Dashboard</Link>
                </li>
              )}
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
            <h6>Connect here</h6>
            <div className="social-media-links">
              <Link
                target="_blank"
                to="https://www.instagram.com/dreamstore_ml"
              >
                <InstagramIcon className="icon" />
              </Link>
              <Link target="_blank" to="https://wa.me/919366479173">
                <WhatsAppIcon className="icon" />
              </Link>
              <Link target="_blank" to="mailto:devilsun402@gmail.com">
                <EmailIcon className="icon" />
              </Link>
            </div>
          </div>

          <hr />
          <span className="copyright">
            <small>
              All Rights Reserved © 2024 | DREAM STORE |{" "}
              <br className="d-block d-md-none d-lg-none" />
              Website designed & developed by{" "}
              <Link
                target="_blank"
                style={{ color: "var(--t)", fontWeight: "500" }}
                to="https://aashirdigital.com/"
              >
                ~@aashirdigital
              </Link>
            </small>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
