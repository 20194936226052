import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Games.css";

const Games = ({ title }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <div className="explore-products-container">
      {/* POPULAR GAMES  */}
      <div className="titlee">
        <h5 className="text-white">Popular Games</h5>
      </div>
      <div className="games-list">
        {products?.map((item, index) => {
          return (
            <div
              key={index}
              className="game"
              onClick={() => navigate(`/product/${item?.name}`)}
            >
              <img
                src={`https://dreamstoreofficial.in/${item?.image}`}
                alt="pro-img"
              />
              <h5 className="m-0">{item?.name}</h5>
              <button className="buy-now">Topup Now</button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Games;
