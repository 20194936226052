import React, { useState } from "react";
import { message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import "./SideMenu.css";

const SideMenu = ({ sideMenu, setSideMenu }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [submenu, setSubmenu] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    message.success("Logout Successful");
    navigate("/login");
  };
  return (
    <div
      className={`sidemenu-container d-block d-md-block d-lg-none ${
        sideMenu ? "active" : ""
      }`}
    >
      <div className="sidemenu">
        <HighlightOffIcon
          onClick={() => setSideMenu(!sideMenu)}
          className="close-icon"
        />
        <ul className="p-0">
          <>
            <li>
              <Link onClick={() => setSideMenu(!sideMenu)} to="/leaderboard">
                <EmojiEventsIcon className="me-1 icon" />
                Leaderboard
              </Link>
            </li>
          </>
          {user && (
            <>
              <li>
                <Link to="/user-dashboard">
                  <DashboardIcon className="icon me-1" />
                  Dashboard
                </Link>
              </li>
              <li>
                <Link to="/orders">
                  <ShoppingBagIcon className="icon me-1" />
                  My Orders
                </Link>
              </li>
              <li>
                <Link to="/my-account">
                  <AccountBoxIcon className="icon me-1" />
                  My Account
                </Link>
              </li>
            </>
          )}
          {!user && (
            <div className="sidemenu-action-btn">
              <Link to="/login">Login</Link>
            </div>
          )}
          {user && (
            <div className="logout" onClick={handleLogout}>
              Logout
              <LogoutIcon className="icon" />
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
