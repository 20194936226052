import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CryptoJS from "crypto-js";
import "./ProductInfo.css";
import { useDispatch, useSelector } from "react-redux";
import getUserData from "../utils/userDataService";
import { setUser } from "../redux/features/userSlice";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const ProductInfo = () => {
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [playerCheck, setPlayerCheck] = useState(null);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const [fields, setFields] = useState({});
  const [mode, setMode] = useState("UPI");
  const [balance, setBalance] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    }
  }
  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user && user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setProductId(defaultId);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateOrderId = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const randomNumbers = Array.from({ length: 4 }, () =>
      Math.floor(Math.random() * 10)
    ).join("");
    const orderId = `${day}${month}${year}${hours}${minutes}${seconds}${randomNumbers}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    getProduct();
  }, [params?.name]);

  useEffect(() => {
    generateOrderId();
  }, []);

  async function handleCheckPlayer() {
    if (!userId || !zoneId) {
      return message.error("Enter ID");
    }
    try {
      const object = {
        region: "brazil",
        userid: userId,
        zoneid: zoneId,
        productid: "13",
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function checkPlaceOrder(e) {
    if (!user?.fname || !user?.email) {
      message.error("You must complete your profile first.");
      return navigate("/my-account");
    }
    if (product?.playerCheck === "yes") {
      if (playerCheck === null) {
        return message.error("Check username");
      }
    }
    if (product?.api === "no") {
      if (userId === "") {
        return message.error("Some Fields are missing");
      }
    } else if (product?.api === "yes") {
      if (userId === "") {
        return message.error("Enter User ID");
      }
      if (zoneId === "") {
        return message.error("Select Server");
      }
    }

    if (product?.api === "yes") {
      if (product?.apiName === "smileOne") {
        if (mode === "UPI") {
          handleSmileOneUpiOrder(e);
        } else {
          handleSmileOneWalletOrder(e);
        }
      } else if (product?.apiName === "moogold") {
        if (mode === "UPI") {
          handleMoogoldUpiOrder(e);
        } else {
          handleMoogoldWalletOrder(e);
        }
      }
    } else {
      if (mode === "UPI") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }

  // smile
  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: selectedPrice,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        redirectUrl: "https://dreamstoreofficial.in/api/smile/status",
        paymentNote:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          amount +
          "@" +
          product?.region +
          "@" +
          product?.name +
          "@" +
          selectedPrice,
      };

      const response = await axios.post("/api/smile/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      // redirecting user
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    e.preventDefault();
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    try {
      const orderObject = {
        orderId: orderId,
        userid: userId.trim(),
        zoneid: zoneId.trim(),
        productid: productId,
        region: product.region,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
      };
      setLoading(true);
      const res = await axios.post("/api/smile/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        setLoading(false);
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // moogold
  async function handleMoogoldUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: selectedPrice,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        redirectUrl: "https://dreamstoreofficial.in/api/moogold/status",
        paymentNote:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          product?.gameName,
      };

      setLoading(true);

      const response = await axios.post("/api/moogold/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      // redirecting user
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  async function handleMoogoldWalletOrder(e) {
    e.preventDefault();
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    try {
      const orderObject = {
        api: "no",
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.gameName,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoading(true);
      const res = await axios.post("/api/moogold/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        setLoading(false);
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  // manual
  const handleUpiOrder = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: selectedPrice,
        customerName: user?.fname,
        customerNumber: user?.mobile,
        customerEmail: user?.email,
        redirectUrl: "https://dreamstoreofficial.in/api/manual/status",
        paymentNote: userId.trim() + "@" + amount + "@" + product?.name,
      };

      const response = await axios.post("/api/manual/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleWalletOrder = async (e) => {
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        client_txn_id: orderId,
        userid: userId.trim(),
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
      };

      setLoading(true);
      const res = await axios.post("/api/manual/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
        setLoading(false);
        navigate("/failure");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="p-info-container area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="pro-img">
          <img src={`https://dreamstoreofficial.in/${product?.image}`} alt="" />
        </div>
        <div className="pro-content">
          <h2>{product?.name}</h2>
          <h6>⚡Instant Recharge || 24x7 Support⚡</h6>
        </div>
      </div>
      <div className="package-details">
        {/* PACKAGES  */}
        <div className="package-container">
          {product?.cost?.map((item, index) => {
            return (
              <div
                onClick={() => {
                  setAmount(item.amount);
                  setPriceAndId(item.amount);
                }}
                key={index}
                className={`amount ${amount === item?.amount && "active"}`}
              >
                <span>
                  <img src={item?.pimg} alt="" />
                  <small>{item.amount}</small>
                </span>
              </div>
            );
          })}
        </div>

        {/* USER FIELDS */}
        <div className="order-info">
          {/* USER ID ZONE ID */}
          <div className="pack-info">
            <span>Order Information</span>
            {product?.apiName === "smileOne" ||
            (product?.apiName === "moogold" &&
              product?.gameName === "15145") ? (
              <>
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder="User ID"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
                <input
                  className="player-tag"
                  type="text"
                  name="zoneid"
                  placeholder="(    ZONE ID    )"
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
                <span className="text-success">
                  {playerCheck && "Username: " + playerCheck}
                </span>
              </>
            ) : (
              <input
                className="player-tag"
                type="text"
                name="playerId"
                placeholder={`${product?.tag || "Enter ID"}`}
                onChange={(e) => setUserId(e.target.value)}
                value={userId}
              />
            )}
            {product?.playerCheck === "yes" && (
              <button className="p-check-btn" onClick={handleCheckPlayer}>
                Check Username
                {loading && (
                  <div class="spinner-grow spinner-grow-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            )}
          </div>

          <div className="mt-4 pack-info">
            <div className="title">
              <span>Payment Mode</span>
            </div>
            <div className="payment">
              <div
                onClick={() => setMode("UPI")}
                className={`upi ${mode === "UPI" && "active"}`}
              >
                {/* <img src={IMAGES.upi} alt="" /> */}
                <h4 className="m-0">UPI</h4>
              </div>
              {/* <div
                onClick={() => setMode("WALLET")}
                className={`wallet ${mode === "WALLET" && "active"}`}
              >
                <div>
                  <AccountBalanceWalletIcon className="icon" />
                  <span className="ms-2">{user && <b>Rs. {balance}</b>}</span>
                </div>
                <h4 className="m-0">Wallet</h4>
              </div> */}
            </div>
          </div>

          <div className="mt-4 pack-info">
            <div className="title">
              <span>Total</span>
              <div className="price ">
                {selectedPrice !== null ? (
                  <h3 className="m-0 mt-3">
                    <b>Rs. {selectedPrice}</b>
                  </h3>
                ) : (
                  "Select an amount to see the price"
                )}
              </div>
            </div>
            {!user ? (
              <button
                className="p-check-btn"
                onClick={() => navigate("/login")}
              >
                Please Login First
              </button>
            ) : product?.stock === "no" ? (
              <button className="p-check-btn" style={{ opacity: "0.7" }}>
                Out of Stock
              </button>
            ) : (
              <button
                disabled={loading}
                onClick={checkPlaceOrder}
                className="p-check-btn"
              >
                {loading && (
                  <div class="spinner-grow spinner-grow-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                )}
                Buy Now
              </button>
            )}
          </div>
        </div>

        <div className="pro-desc-container">
          <div className="pro-desc">
            <span>{product?.desc}</span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductInfo;
