import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SideMenu from "./SideMenu";
import Backdrop from "./Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Tippy from "@tippyjs/react";
import LogoutTippy from "./LogoutTippy";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import SearchContainer from "../SearchContainer";
import getUserData from "../../utils/userDataService.js";
import "./Header.css";

const Header = () => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sideMenu, setSideMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [search, setSearch] = useState(false);
  const [cartMenu, setCartMenu] = useState(false);
  const [balance, setBalance] = useState("");

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  return (
    <>
      <header className="header">
        <div className="header-main">
          <div
            className="burger-icon d-block d-lg-none"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <MenuIcon className="icon" />
          </div>
          <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <Backdrop sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <div className="logo" onClick={() => navigate("/")}>
            <span>
              Dream<span className="blue">Store</span>
            </span>
          </div>
          <div className="menus d-none d-md-none d-lg-block">
            <ul className="p-0">
              <li>
                <Link to="/leaderboard">Leaderboard</Link>
              </li>
              {!user && (
                <li>
                  <Link to="/login">My Account</Link>
                </li>
              )}
              {user && (
                <>
                  <li>
                    <Link to="/orders">Orders</Link>
                  </li>
                  <li>
                    <Link to="/user-dashboard">Dashboard</Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="action-btns">
            {/* {user && (
              <div onClick={() => navigate("/wallet")} className="wallet-cont">
                <span>{balance}</span>
                <span className="ms-2">
                  <AccountBalanceWalletIcon className="icon" />
                </span>
              </div>
            )} */}
            <SearchIcon onClick={() => setSearch(!search)} className="icon" />
            <Tippy
              interactive
              theme="light"
              content={<LogoutTippy user={user && user} />}
            >
              <span
                // onClick={handleLogout}
                className="menu-img-container d-flex"
              >
                {user ? (
                  <>
                    <PersonIcon
                      className="icon"
                      onClick={() => navigate("/user-dashboard")}
                    />
                    <KeyboardArrowDownIcon className="d-lg-block d-md-none d-none text-white" />
                  </>
                ) : (
                  <PersonIcon
                    className="icon"
                    onClick={() => navigate("/login")}
                  />
                )}
              </span>
            </Tippy>
          </div>
        </div>
      </header>
      <SearchContainer search={search} setSearch={setSearch} />
    </>
  );
};

export default Header;
